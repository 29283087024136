<template>
  <div class="teacher-list">
    <el-dialog
      title="修改商品价格"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="115px">
        <el-form-item class="checkedOne"
          ><el-checkbox v-model="form.isRetail">普通用户零售定价</el-checkbox>
        </el-form-item>
        <div v-if="form.isRetail">
          <div>
            <el-form-item label="商品售价：">
              <el-input
                v-model="form.price"
                type="number"
                placeholder="请输入商品售价（必填）"
                clearable
              >
              </el-input
              >（元）
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.isPromote">折扣价格零售</el-checkbox>
            </el-form-item>
            <el-form-item label="折扣价格：">
              <el-input
                v-model="form.promotionPrice"
                type="number"
                placeholder="请输入折扣价格"
                :disabled="!form.isPromote"
                clearable
              >
              </el-input
              >（元）
            </el-form-item>
            <el-form-item label="起止时间：">
              <el-date-picker
                v-model="date1"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :disabled="!form.isPromote"
                @change="getDate1"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item>
            <el-checkbox v-model="form.isFree">是否免费</el-checkbox>
          </el-form-item>
          <div v-if="form.isFree">
            <el-form-item>
              <el-checkbox v-model="form.isFreeForever">永久</el-checkbox>
            </el-form-item>
            <el-form-item label="起止时间：">
              <el-date-picker
                v-model="date"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :disabled="form.isFreeForever"
                @change="getDate"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <el-form-item v-if="form.resourceType == 2" class="checkedOne"
          ><el-checkbox v-model="form.isWholesale">企业批量采购定价</el-checkbox>
        </el-form-item>
        <div v-if="form.isWholesale && form.resourceType == 2">
          <el-form-item
            ><el-checkbox v-model="form.isQuantity">按购买人数采购</el-checkbox>
          </el-form-item>
          <el-form-item label="商品定价：">
            <el-input
              v-model="form.quantityPrice"
              type="number"
              placeholder="请输入商品原价"
              clearable
              :disabled="!form.isQuantity"
            >
            </el-input
            >（元）
          </el-form-item>
          <el-form-item
            ><el-checkbox v-model="form.isBuyout">不限人数一年买断</el-checkbox>
          </el-form-item>
          <el-form-item label="商品定价：">
            <el-input
              v-model="form.buyoutPrice"
              type="number"
              placeholder="请输入商品原价"
              clearable
              :disabled="!form.isBuyout"
            >
            </el-input
            >（元）
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="edit">确定修改</el-button>
      </span>
    </el-dialog>
    <table-list
      title="商品列表"
      :loading="loading"
      :search-form="searchForm"
      :data="centerList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{ selection: true, operatesWidth: 210 }"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '创建商品',
    method: _this.handleAdd,
  },
  {
    label: '上架',
    method: _this.handleUp,
  },
  {
    label: '下架',
    method: _this.handleDown,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '商品标题',
    prop: 'name',
  },
  {
    label: '商品状态',
    type: 'select',
    prop: 'publishStatus',
    children: [
      { value: 0, label: '下架' },
      { value: 1, label: '上架' },
    ],
  },
  {
    label: '商品类型',
    type: 'select',
    prop: 'resourceType',
    children: [
      { value: 1, label: '班级' },
      { value: 2, label: '课程' },
    ],
  },
  {
    label: '上传时间',
    type: 'picker',
    prop: 'createTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'pic',
    label: '商品封面',
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'width:80px;height:50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'name',
    label: '商品标题',
  },
  {
    prop: 'resourceType',
    label: '商品类型',
    formatter: row => {
      return { 1: '班级', 2: '课程' }[row.resourceType]
    },
  },
  {
    prop: 'price',
    label: '价格',
    render: (h, { row }) => {
      return h('div', [
        h('div', '零售价：￥' + row.price),
        row.resourceType == 2 && row.isWholesale && row.isQuantity
          ? h('div', '批量按量：￥' + row.quantityPrice)
          : null,
        row.resourceType == 2 && row.isWholesale && row.isBuyout
          ? h('div', '按年买断：￥' + row.buyoutPrice)
          : null,
      ])
    },
  },
  {
    prop: 'sale',
    label: '销量',
  },
  {
    prop: 'createUserName',
    label: '创建人',
  },
  {
    prop: 'publishTime',
    label: '上架时间',
  },
  {
    prop: 'status',
    label: '状态',
    formatter: row => {
      return { 0: '待审核', 1: '审核通过', 2: '审核不通过', 3: '待发布', 4: '已上架', 9: '未知' }[
        row.status
      ]
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '修改商品价格',
    method: _this.handleEdit1,
  },
  {
    label: '修改商品信息',
    method: _this.handleEdit2,
  },
]
import TableList from '@/components/TableList'
import { centerList, productPublish, priceUpdate } from '@/api/center'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      loading: false,
      centerList: [],
      selectList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        name: '',
        publishEndTime: '',
        publishStartTime: '',
        publishStatus: '',
        resourceType: '',
      },
      centerDialogVisible: false,
      date: null, //免费时间戳
      date1: null, //打折时间戳
      form: {
        isRetail: '', //是否零售
        isFree: '', //是否免费
        isFreeForever: true, //是否永久免费
        freeStartTime: '', //免费开始时间
        freeEndTime: '', //免费结束时间
        price: '', //商品售价
        originalPrice: '', //商品原价
        isPromote: '', //是否折扣价
        promotionPrice: '', //折扣价格
        promotionStartTime: '', //打折开始时间
        promotionEndTime: '', //打折结束时间
        isWholesale: '', //是否企业批量
        isQuantity: '', //是否支持按人购买
        quantityPrice: '', //按人购买价格
        isBuyout: '', //是否支持不限人数一年买断
        buyoutPrice: '', //一年买断价格
        productId: '', //
        resourceType: '',
      },
    }
  },
  mounted() {
    this.getCenter()
  },
  methods: {
    //修改价格
    edit() {
      if (this.form.isRetail == false && this.form.isWholesale == false)
        return this.$message.warning('请至少选择一种售卖方式！')
      if (
        this.form.isRetail == true &&
        this.form.isFree == true &&
        this.form.isFreeForever == false &&
        this.date == null
      )
        return this.$message.warning('请选择免费起止时间！')

      if (this.form.isRetail == true && this.form.price === '')
        return this.$message.warning('请输入商品零售售价！')
      if (this.form.isRetail == true && this.form.price < 0)
        return this.$message.warning('商品零售售价不能小于0！')
      if (
        this.form.isRetail == true &&
        this.form.isPromote == true &&
        this.form.promotionPrice === ''
      )
        return this.$message.warning('请输入商品零售打折价格！')
      if (this.form.isRetail == true && this.form.isPromote == true && this.form.promotionPrice < 0)
        return this.$message.warning('商品零售打折价格不能小于0！')
      if (this.form.isRetail == true && this.form.isPromote == true && this.date1 == null)
        return this.$message.warning('请输入打折起止时间！')

      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isQuantity == true &&
        this.form.quantityPrice === ''
      )
        return this.$message.warning('请输入按购买人数采购商品定价！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isQuantity == true &&
        this.form.quantityPrice < 0
      )
        return this.$message.warning('按购买人数采购商品定价不能小于0！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isBuyout == true &&
        this.form.buyoutPrice === ''
      )
        return this.$message.warning('请输入不限人数一年买断商品定价！')
      if (
        this.form.resourceType == 2 &&
        this.form.isWholesale == true &&
        this.form.isBuyout == true &&
        this.form.buyoutPrice < 0
      )
        return this.$message.warning('不限人数一年买断商品定价不能小于0！')
      this.priceUpdate()
    },
    async getCenter() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(centerList(this.pager))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.centerList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.pager.name = queryParams.name
      this.pager.publishStatus = queryParams.publishStatus
      this.pager.resourceType = queryParams.resourceType
      if (queryParams.createTime && queryParams.createTime !== 0) {
        this.pager.publishStartTime = queryParams.createTime[0]
        this.pager.publishEndTime = queryParams.createTime[1]
      } else {
        this.pager.publishStartTime = ''
        this.pager.publishEndTime = ''
      }
      this.getCenter()
    },
    //创建商品
    handleAdd() {
      this.$router.push('/scenter/sallManger/add')
    },
    //上架
    handleUp() {
      if (!this.selectList.length) return this.$message.warning('请先选择要上架的商品')
      let productId = []
      this.selectList.map(item => productId.push(item.productId))
      this.$confirm('确认上架商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(productPublish({ productId, publishStatus: 1 }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('上架成功')
        this.getCenter()
      })
    }, //下架
    handleDown() {
      if (!this.selectList.length) return this.$message.warning('请先选择要下架的商品')
      let productId = []
      this.selectList.map(item => productId.push(item.productId))
      this.$confirm('确认下架商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(productPublish({ productId, publishStatus: 0 }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('下架成功')
        this.getCenter()
      })
    },
    // 删除
    handleDelete() {
      if (!this.selectList.length) return this.$message.warning('请先选择要删除的商品')
      let selectIds = []
      this.selectList.map(item => selectIds.push(item.productId))
      this.$confirm('确认删除商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        // const [, err] = await to(studentRemove({ selectIds }))
        // if (err) return this.$message.warning(err.msg)
        // this.$message.success('删除成功')
        // this.getCenter()
      })
    },
    //修改商品价格
    handleEdit1(row) {
      this.form.isFree = row.isFree
      this.form.isBuyout = row.isBuyout
      this.form.buyoutPrice = row.buyoutPrice
      this.form.isQuantity = row.isQuantity
      this.form.isRetail = row.isRetail
      this.form.quantityPrice = row.quantityPrice
      this.form.price = row.price
      this.form.productId = row.productId
      this.form.isPromote = row.isPromote
      this.form.promotionPrice = row.promotionPrice
      this.form.originalPrice = row.originalPrice
      this.form.isWholesale = row.isWholesale
      this.date = [row.freeStartTime || '', row.freeEndTime || '']
      this.date1 = [row.promotionStartTime || '', row.promotionEndTime || '']
      this.form.promotionStartTime = row.promotionStartTime
      this.form.promotionEndTime = row.promotionEndTime
      this.form.freeStartTime = row.freeStartTime
      this.form.freeEndTime = row.freeEndTime
      this.form.resourceType = row.resourceType
      this.centerDialogVisible = true
    },
    //修改商品信息
    handleEdit2(row) {
      this.$router.push({
        path: '/scenter/sallManger/edit',
        query: {
          resourceId: row.resourceId,
          resourceType: row.resourceType,
          productId: row.productId,
        },
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCenter()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCenter()
    },
    //获取免费时间段
    getDate() {
      if (this.date == null) {
        this.form.freeStartTime = ''
        this.form.freeEndTime = ''
      } else {
        this.form.freeStartTime = this.date[0]
        this.form.freeEndTime = this.date[1]
      }
    },
    //获取打折时间段
    getDate1() {
      if (this.date1 == null) {
        this.form.promotionStartTime = ''
        this.form.promotionEndTime = ''
      } else {
        this.form.promotionStartTime = this.date1[0]
        this.form.promotionEndTime = this.date1[1]
      }
    },

    async priceUpdate() {
      const [, err] = await to(priceUpdate(this.form))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改成功')
      this.centerDialogVisible = false
      this.getCenter()
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-list {
  .el-form-item {
    margin: 15px 0px;
  }
  .el-input {
    width: 350px;
  }
  .checkedOne {
    background-color: #f6f6f6;
  }
}
</style>
